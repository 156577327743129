import React, { useState, useRef, useEffect } from 'react';
import HeroSvg from './hero.svg';
import Logo from './logo.png'; 

const options = {
    "About us": "about",
    "Courses": "courses",
    "Events": "events",
    "Contact us": "contact"
    // Add more options as needed
};



const Hero = () => {
    const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);

    const sidePaneRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (sidePaneRef.current && !sidePaneRef.current.contains(event.target)) {
                setIsSidePaneOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sidePaneRef]);

    const toggleSidePane = () => {
        setIsSidePaneOpen(!isSidePaneOpen);
    };

    const scrollToSection = (section) => {
        console.log('section', section);
        const retSection = document.getElementById(section);
        retSection.scrollIntoView({ behavior: 'smooth' });
      };

    return (
        <div className="relative h-screen overflow-hidden bg-sky-900">
            <img src={HeroSvg} className="absolute object-cover w-full h-full"/>
            <div className="absolute inset-0 bg-black opacity-25"></div>
            <header className="absolute top-0 left-0 right-0 z-20">
                <nav className="container px-6 py-4 mx-auto md:px-12">
                    <div className="items-center justify-between md:flex">
                        <div className="flex items-center justify-between">
                            <a href="#" className="text-white">
                                <img src={Logo} alt="Logo" className="h-12 md:h-16 lg:h-20 xl:h-24 mr-2" /> {/* Adjust height here */}
                            </a>
                            <div className="md:hidden">
                                <button onClick={toggleSidePane} className="text-white focus:outline-none">
                                    <svg className="w-12 h-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="items-center hidden md:flex">
                            {Object.entries(options).map(([option, href]) => (
                                <button onClick={() => scrollToSection(href)} className="mx-3 text-lg text-white uppercase cursor-pointer hover:text-gray-300">{option}</button>
                            ))}
                        </div>
                    </div>
                </nav>
            </header>
            {isSidePaneOpen && (
                <div className="fixed inset-0 z-30 overflow-hidden" onClick={toggleSidePane}>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div ref={sidePaneRef} className="absolute inset-y-0 right-0 w-64 bg-white shadow-lg">
                        <div className="py-4 focus:bg-gray-200 rounded-md">
                            {Object.entries(options).map(([option, href]) => (
                                <button onClick={() => scrollToSection(href)} className="block px-6 py-3 text-sky-800 hover:bg-gray-200 hover:text-gray-900">{option}</button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <div className="container relative z-10 flex items-center px-6 py-32 mx-auto md:px-12 xl:py-40">
                <div className="relative z-10 flex flex-col items-start lg:w-3/5 xl:w-2/5">
                    <span className="font-bold text-yellow-400 uppercase">Code - Create - Conquer</span>
                    <h1 className="mt-4 text-6xl font-bold leading-tight text-white sm:text-7xl">
                        Future Crafters
                        <br/>
                        Coding Academy
                    </h1>
                    <button onClick={() => scrollToSection('about')} className="block px-4 py-3 mt-10 text-lg font-bold text-gray-800 uppercase bg-white rounded-lg hover:bg-gray-100">Discover</button>
                </div>
            </div>
        </div>
    );
}

export default Hero;
