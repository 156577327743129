import React from 'react';
import { useParams, Link } from 'react-router-dom';

const EventImages = () => {
  const { eventName } = useParams();

  // Assuming you have stored your images in the public directory
  const images = require.context('../_data/gallery', true, /\.(jpg)$/);
  const eventImages = images.keys().filter(key => key.includes(eventName));

  return (
    <div className="bg-gradient-to-b from-sky-50 to-sky-100 min-h-screen">
        <div className="container mx-auto px-4 py-8 ">
        <h2 className="text-3xl font-bold mb-8 text-center text-sky-900">{eventName} - Event Images</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {eventImages.map((image, index) => (
            <Link
                key={index}
                to={`/events/${eventName}/${image.split('/').pop().split('.')[0]}`}
                className="block"
            >
                <img
                src={images(image)}
                alt={`${eventName} Image ${index + 1}`}
                className="w-full h-48 object-cover rounded-lg cursor-pointer"
                />
            </Link>
            ))}
        </div>
        </div>
    </div>
  );
}

export default EventImages;
