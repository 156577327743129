import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  // Define social media links
  const socialMediaLinks = [
    { name: "WhatsApp", icon: faWhatsapp, url: "whatsapp://send?phone=9043546160" },
    { name: "Email", icon: faEnvelope, url: "mailto:futurecrafterscodingacademy@gmail.com" },
  ];

  return (
    <footer
      id="contact"
      className="bg-[conic-gradient(at_bottom_left,_var(--tw-gradient-stops))] from-sky-900 via-teal-800 to-gray-300 w-full py-8"
    >
      <div className="max-w-screen-xl px-4 mx-auto">
        {/* Contact details */}
        <div className="flex flex-wrap justify-center max-w-screen-md mx-auto text-lg font-light space-x-6">
          {/* Address */}
          <div className="my-2 text-center">
            <p className='text-gray-300 mb-1'>Chennai Office</p>
            <p className="text-gray-400 mb-1">#6/34, Perumal Koil Street,</p>
            <p className="text-gray-400">Arumbakkam, Chennai - 600106</p>
          </div>
          <div className="my-2 text-center">
            <p className='text-gray-300 mb-1'>Cheyyar Office</p>
            <p className="text-gray-400 mb-1">#9/89, Kanniamman Kovil Street, <br/>Arni Main Road,</p>
            <p className="text-gray-400">(Near by cheyyar fire station & Raga mahal), Cheyyar, Tamilnadu - 604407</p>
          </div>
        </div>

        {/* Social media icons */}
        <div className="pt-8 flex max-w-xs mx-auto items-center justify-center space-x-4">
          {socialMediaLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              className="text-gray-400 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white"
            >
              <FontAwesomeIcon icon={link.icon} size="2x" />
            </a>
          ))}
        </div>

        {/* Created by section */}
        <div className="text-center text-gray-50 pt-10 sm:pt-12 font-light flex items-center justify-center">
          {"Created by⚡ "}
          <a
            href="https://github.com/iamadhee"
            className="underline decoration-lime-300 underline-offset-"
          >
            @iamadhee
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
