import { Link } from 'react-router-dom';

const courses = [
  {
    category: 'For College Students And Working Professionals',
    description: 'Explore our range of courses tailored to meet the needs of college students. Whether you\'re looking to expand your knowledge or gain new skills, we have something for you.',
    slug: 'college'
  },
  {
    category: 'For School Students',
    description: 'Check out our courses designed specifically for school students. From foundational subjects to advanced topics, we have courses to help you excel in your studies.',
    slug: 'school'
  }
];

const CoursesOffered = () => {
  return (
    <div id="courses" className="bg-[conic-gradient(at_bottom_left,_var(--tw-gradient-stops))] from-sky-900 via-teal-800 to-gray-300 text-white">
      <div className="container mx-auto py-12 px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Courses We Offer</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {courses.map((course, index) => (
            <div key={index} className="rounded-lg overflow-hidden bg-white shadow-md">
              <div className="px-6 py-8">
                <div className="font-bold text-xl mb-4 text-black">{course.category}</div>
                <p className="text-gray-900 text-base">
                  {course.description}
                </p>
              </div>
              <div className="px-6 py-4 bg-gray-100">
                <Link to={`/courses/${course.slug}`} className="inline-block bg-sky-900 hover:bg-sky-700 text-white font-bold py-2 px-6 rounded-md transition duration-300 ease-in-out">
                  View Courses
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CoursesOffered;
