import { Link } from 'react-router-dom';
import courseList from '../_data/courses.js';
import { useParams } from 'react-router-dom';


const FullCourses = () => {
  // Filter courses by category
  const { category } = useParams();

  const renderCourses = courseList.filter(course => course.category === category);

  return (
    <div className="bg-gradient-to-b from-sky-50 to-sky-100 min-h-screen">
      <div className="container mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center text-sky-900 mb-8">
          Explore Our {category} Courses
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {renderCourses.map(course => (
            <CourseCard key={course.courseName} course={course} />
          ))}
        </div>
      </div>
    </div>
  );
};



const CourseCard = ({ course }) => {
  const index = courseList.indexOf(course);

  // Dynamically import the image using the index
  const image = require(`../_data/img/${index}.jpeg`);
  // const image=''

  return (
    <div className="relative overflow-hidden shadow-lg mx-2 my-4 sm:mx-4 sm:my-4 md:mx-2 md:my-4 lg:mx-2 lg:my-4 rounded-lg border-2 border-gray-200 bg-white">
      <Link to={`/courses/${course.category}/${index}`} className="block">
        <div className="h-64 relative overflow-hidden transition-transform duration-300 transform hover:scale-105 focus:scale-105">
          <img
            src={image} // Accessing the image from the dynamically imported module
            className="w-full h-full object-cover rounded-t-lg"
            alt={course.courseName}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-90 rounded-t-lg" />
        </div>
        <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
          <h3 className="text-lg font-semibold">{course.courseName}</h3>
        </div>
      </Link>
    </div>
  );
};


export default FullCourses;
