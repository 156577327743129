const Info = () => {
  return (
    <section id="about">
      <div class="container p-4 mx-auto rounded-md bg-white max-w-7xl sm:p-6 lg:p-8 my-32">
        <div class="flex flex-wrap -mx-8">
          <div class="w-full px-8 lg:w-1/2">
            <div class="pb-12 mb-12 border-b lg:mb-0 lg:pb-0 lg:border-b-0">
              <h2 class="mb-4 text-3xl font-bold lg:text-4xl font-heading">
                Empowering the Next Generation of Tech Innovators
              </h2>
              <p class="mb-8 leading-loose text-gray-500">
                At <t className="font-bold">Future Crafters Coding Academy (FCCA)</t>, we are committed to equipping students with cutting-edge software engineering and AI skills. Our curriculum is meticulously designed to blend theoretical knowledge with hands-on experience, preparing you for the challenges of tomorrow's tech landscape.
              </p>
              <p class="mb-8 leading-loose text-gray-500 text-left">
              Our dedication to your triumph in the tech world transcends the confines of conventional education. At FCCA, we offer unmatched career placement support that meticulously prepares you for the competitive job market. From personalized resume enhancement workshops and rigorous interview preparation sessions to exclusive access to coveted job opportunities, our career services are tailored to showcase your unique talents and ambitions. With a dedicated team of career experts at your side, we actively facilitate introductions to our wide network of industry leaders and innovative companies, ensuring you're not just ready for a job in tech but poised for a distinguished career. Join us at FCCA, where your journey towards a successful future in technology is our utmost priority.
              </p>
              <div class="w-full md:w-1/3">
              </div>
            </div>
          </div>
          <div class="w-full px-8 lg:w-1/2">
            <ul class="space-y-12">
              <li class="flex -mx-4">
                <div class="px-4">
                  <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-sky-900 rounded-full font-heading bg-lime-50">
                    1
                  </span>
                </div>
                <div class="px-4">
                  <h3 class="my-4 text-xl font-semibold">
                    Comprehensive Curriculum
                  </h3>
                  <p class="leading-loose text-gray-500">
                    From foundational programming to advanced AI principles, our courses are designed to provide a comprehensive learning experience, ensuring a deep understanding of technology and its applications.
                  </p>
                </div>
              </li>
              <li class="flex -mx-4">
                <div class="px-4">
                  <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-sky-900 rounded-full font-heading bg-lime-50">
                    2
                  </span>
                </div>
                <div class="px-4">
                  <h3 class="my-4 text-xl font-semibold">
                    Experienced Faculty
                  </h3>
                  <p class="leading-loose text-gray-500">
                    Our team consists of seasoned educators and industry experts who bring real-world experience into the classroom, providing mentorship and insights that prepare you for a successful career in tech.
                  </p>
                </div>
              </li>
              <li class="flex -mx-4">
                <div class="px-4">
                  <span class="flex items-center justify-center w-16 h-16 mx-auto text-2xl font-bold text-sky-900 rounded-full font-heading bg-lime-50">
                    3
                  </span>
                </div>
                <div class="px-4">
                  <h3 class="my-4 text-xl font-semibold">
                    Community and Networking
                  </h3>
                  <p class="leading-loose text-gray-500">
                    We believe in the power of community and networking. Our academy provides various platforms for students to connect with peers, tech leaders, and potential employers, fostering a supportive ecosystem for growth and opportunities.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;